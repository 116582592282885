<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="primary" @click="save(false)" :loading="isFormLoading">
            {{ $t('data_input.save_close') }}
          </base-button>
          <base-button size="sm" type="primary" @click="save(true)" :loading="isFormLoading">
            {{ $t('data_input.save_new') }}
          </base-button>
          <base-button size="sm" type="primary" @click="save(true, true)" :loading="isFormLoading" v-if="item.id">
            {{ $t('data_input.save_next') }}
          </base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card>
            <template slot="header">
              <h3 class="mb-0">{{ $t('data_input.informations') }}</h3>
            </template>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <base-input label="ID">
                  <el-input placeholder="ID" v-model="item.key" />
                </base-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <base-input v-if="!project.pt_sync_enabled" :label="$t('message.PTNumberRange')">
                  <el-select v-model="pt_number1" placeholder="Auswählen" class="col-4">
                    <el-option v-for="pt in pt_numbers" :key="pt" :label="pt" :value="pt">
                    </el-option>
                  </el-select>
                  <el-input :placeholder="$t('global.input_placeholder')" class="col-4" v-model="pt_number2"
                    :disabled="project ? !project.pt_number : true">
                  </el-input>
                </base-input>
                <base-input v-if="project.pt_sync_enabled" :label="$t('message.PTNumberRange')"
                  v-model="pt_number_composed" :disabled="true">
                </base-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <base-input :label="$t('data_input.order_label')" :validator="$v.item.order_no">
                  <el-select v-model="item.order_no" filterable
                    :placeholder="$t('data_input.input_select_order_placeholder')">
                    <el-option v-for="option in customer_orders" :key="option.order_no" 
                      :label="option.description ? option.order_no + ' - ' + option.description : option.order_no"
                      :value="option.order_no">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-4 col-md-6">
                <base-input :label="$t('data_input.address_label')" :validator="$v.item.p_address_id">
                  <el-select v-model="item.p_address_id" filterable
                              :placeholder="$t('data_input.input_select_address_placeholder')"
                              popper-class="is-error">
                    <el-option v-for="option in projectAddresses"
                                :key="option.id"
                                :label="option.address.name"
                                :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <base-input :label="$t('data_input.ride_label')" :validator="$v.item.ride_id">
                  <el-select v-model="item.ride_id" filterable
                              :placeholder="$t('data_input.input_select_ride_placeholder')">
                    <el-option v-for="option in projectRides"
                                :key="option.id"
                                :label="option.notice ? option.vehicle.type + ' (' + option.notice + ')' : option.vehicle.type"
                                :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <base-input :label="$t('message.tools')">
                  <el-select v-model="item.project_tool_id" filterable
                              :placeholder="$t('message.ChooseTool')"
                              popper-class="is-error">
                    <el-option v-for="option in projectTools"
                                :key="option.id"
                                :label="option.name"
                                :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-6">
                <base-input :label="$t('message.DeadlinePrint')">
                  <flat-picker
                          slot-scope="{focus, blur}"
                          v-model="item.deadline_print"
                          :placeholder="addressDeadlinePrint"
                          @on-open="focus"
                          @on-close="blur"
                          :config="dateTimePicker"
                          class="form-control datepicker"
                  />
                </base-input>
              </div>

              <div class="col-lg-4 col-md-6">
                <base-input :label="$t('data_input.priority_label')">
                  <el-checkbox border v-model="item.priority">{{ $t('data_input.priority') }}</el-checkbox>
                  <template v-if="item.priority">
                    <el-input type="textarea" autosize :placeholder="$t('data_input.reason_placeholder')"
                      v-model="item.priority_reason" />

                    <el-radio v-model="item.priority_internal" :label="1">Intern</el-radio>
                    <el-radio v-model="item.priority_internal" :label="0">Extern</el-radio>
                    <base-input label="">
                      <flat-picker slot-scope="{focus, blur}" v-model="item.priority_date"
                        :placeholder="$t('message.ChooseDatePicker')" @on-open="focus" @on-close="blur"
                        :config="dateTimePicker" class="form-control datepicker" />
                    </base-input>
                  </template>
                </base-input>
              </div>

              <div class="col-lg-2 col-md-6">
                <base-input :label="$t('data_input.quality_control')" :validator="$v.item.qc_user_id">
                  <el-checkbox border v-model="item.quality_control">{{ $t('global.yes') }}</el-checkbox>

                  <el-select v-if="item.quality_control" v-model="item.qc_user_id" filterable
                    :placeholder="$t('message.teamMemberSelect')" popper-class="is-error">
                    <el-option v-for="option in qcEmployee" :key="option.id" :label="option.name" :value="option.id">
                    </el-option>
                  </el-select>

                </base-input>
              </div>
              <div class="col-lg-2 col-md-6">
                <base-input :label="$t('data_input.divider_sheet')">
                  <el-checkbox border v-model="item.divider_sheet">{{ $t('global.yes') }}</el-checkbox>
                </base-input>
              </div>
            </div>
          </card>

          <card>
            <template slot="header">
            </template>

            <h3 class="mb-0">{{ $t('data_input.material_manager.label') }}</h3>
            <material v-for="(material, i) in materials" :material="material" :pos="i" title="Material"
              @change="materialsChanged()" @delete="deleteMaterial(material)" @reset="resetMaterial(material)"
              @purchase="purchaseChecked($event)" :validCats="setCategoryOptions('MAT')"
              :validator="$v.item.materials.$each[i]" :key="'mat-' + i">
            </material>
            <div class="row" v-if="materials.length > 0">
              <div class="col-md-2">
                <base-input :label="$t('data_input.material_manager.bemerkung_druck')">
                  <textarea class="form-control" v-model="item.bemerkung_druck" rows="3"></textarea>
                </base-input>
              </div>
              <div class="col-md-2">
                <base-input :label="$t('data_input.material_manager.bemerkung_dv')">
                  <textarea class="form-control" v-model="item.bemerkung_dv" rows="3"></textarea>
                </base-input>
              </div>
              <div class="col-md-2">
                <base-input :label="$t('data_input.material_manager.bemerkung_konfektion')">
                  <textarea class="form-control" v-model="item.bemerkung_konfektion" rows="3"></textarea>
                </base-input>
              </div>
              <div class="col-md-2">
                <base-input :label="$t('data_input.material_manager.print_file_path')" v-model="item.print_file_path">
                </base-input>
              </div>
              <div class="col-md-2">
                <base-input v-if="this.purchaseField" :label="$t('data_input.material_manager.purchase_requirement')">
                  <textarea class="form-control" v-model="item.purchase_requirement" rows="3"></textarea>
                </base-input>
              </div>
            </div>
            <div class="row text-mute" v-if="materials.length === 0">
              <div class="col-md-12 text-center">
                {{ $t('data_input.material_manager.materials_empty') }}
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-4">
                <base-button size="sm" type="primary" @click="addMaterial('MAT')">
                  {{ $t('data_input.material_manager.add') }}
                </base-button>
              </div>
              <div class="col-md-8">
              </div>
            </div>

            <div v-if="constructionsVisible">
              <material v-for="(material, i) in constructions" :material="material" :pos="i"
                :title="$t('data_input.construction')" @change="materialsChanged()" @delete="deleteMaterial(material)"
                @reset="resetMaterial(material)" @variantChanged="corVariantChanged()"
                :validator="$v.constructions.$each[i]" :validCats="setCategoryOptions('KON')" :key="'kon-' + i">
              </material>

              <div class="row" v-if="constructions.length > 0">
                <div class="col-md-2">
                  <base-input :label="$t('data_input.material_manager.bemerkung_logistik')">
                    <textarea class="form-control" v-model="item.bemerkung_logistik" rows="3"></textarea>
                  </base-input>
                </div>
              </div>
            </div>

            <div class="row text-mute" v-if="constructions.length === 0">
              <div class="col-md-12 text-center">
                {{ $t('data_input.material_manager.constructions_empty') }}
              </div>
            </div>
            <div class="row border-bottom mb-4 pb-4">
              <div class="col-md-4">
                <base-button size="sm" type="primary" @click="addMaterial('KON')">
                  {{ $t('data_input.material_manager.add_construction') }}
                </base-button>
              </div>
              <div class="col-md-8">
              </div>
            </div>

            <div class="row">
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <base-input type="number" :label="$t('global.price_total')" :placeholder="$t('global.price_total')"
                  disabled v-model="item.price_total" />
              </div>
            </div>

          </card>
          <card>
            <template slot="header">
              <h3 class="mb-0">{{ $t('data_input.motive') }}</h3>
            </template>

            <div class="row">
              <div class="col-md-3">
                <base-input :label="$t('data_input.area_label')" :validator="$v.item.area_id">
                  <el-select v-model="item.area_id" filterable
                    :placeholder="$t('data_input.input_select_area_placeholder')">
                    <el-option v-for="option in options.filter(o => o.type == 'AREA')" :key="option.id"
                      :label="option.name" :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('message.color')">
                  <el-select v-model="item.color_id" filterable
                    :placeholder="$t('data_input.input_select_color_placeholder')">
                    <el-option v-for="option in options.filter(o => o.type == 'COLOR')" :key="option.id"
                      :label="option.name" :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('data_input.position_label')" :validator="$v.item.bhb_position_id">
                  <el-select v-model="item.bhb_position_id" filterable
                    :placeholder="$t('data_input.input_select_position_im_placeholder')">
                    <el-option v-for="option in options.filter(o => o.type == 'BHBPOS')" :key="option.id"
                      :label="option.name" :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input :label="$t('data_input.assembly')">
                  <el-select v-model="item.assembly_info_id" filterable
                    :placeholder="$t('data_input.input_select_assembly_placeholder')">
                    <el-option v-for="option in globalOptions.filter(o => o.type == 'MONTAGE')" :key="option.id"
                      :label="currentLocale === 'de' ? option.name : option.name_en" :value="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 mb-3">
                <el-input :placeholder="$t('data_input.add_area_placeholder')" v-model="newAreaValue" />
                <base-button size="sm" type="primary" @click="saveNewOption('AREA')">{{
                  $t('global.save')
                }}</base-button>
              </div>
              <div class="col-md-3 mb-3">
                <el-input :placeholder="$t('data_input.add_color_placeholder')" v-model="newColorValue" />
                <base-button size="sm" type="primary" @click="saveNewOption('COLOR')">{{
                  $t('global.save')
                }}</base-button>
              </div>
              <div class="col-md-3 mb-3">
                <el-input :placeholder="$t('data_input.add_position_placeholder')" v-model="newPositionValue" />
                <base-button size="sm" type="primary" @click="saveNewOption('BHBPOS')">{{
                  $t('global.save')
                }}</base-button>
              </div>
            </div>
            <div class="row" style="height: 1000px;">
              <div class="col-xl-6">


                <image-editor :is-save-button-visible="false" ref="imageEditor" @input="annotationChanged"
                  :value="annotations" :is-loading="isAnnotationLoading" @delete="annotationDeleted" />


                <!-- <base-input label="Szene" >

                    <document-upload type="position" :id="item.id" name="szene" @changed="onSceneChanged" placeholder="Bitte hochladen"></document-upload>
                  </base-input> -->
              </div>
              <div class="col-xl-6">
                <base-input v-if="item" :label="$t('data_input.designs')">
                  <document-upload ref="docUpload" type="position" :documents="item.documents" name="designs"
                    multiple=false @changed="docs => this.designs = docs" @removed="onDesignRemoved"
                    :placeholder="$t('data_input.image_editor_placeholder')"></document-upload>
                </base-input>
              </div>
            </div>
          </card>
          <card>
            <template slot="header">
              <h3 class="mb-0">{{ $t('data_input.miscellaneous') }}</h3>
            </template>
            <div class="row">
              <div class="col-md-12">
                <base-input :label="$t('data_input.additional_information_checklist')">
                  <textarea class="form-control" v-model="item.cl_info" rows="3"
                    :placeholder="$t('data_input.additional_information_checklist_placeholder')"></textarea>
                </base-input>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input :label="$t('data_input.note_im')">
                  <textarea class="form-control" v-model="item.bhb_note" rows="3" :maxLength="200"
                    :placeholder="$t('data_input.note_im_placeholder')">
                    </textarea>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input :label="$t('data_input.note_im_en')">
                  <textarea class="form-control" v-model="item.bhb_note_en" rows="3" :maxLength="200"
                    :placeholder="$t('data_input.note_im_placeholder_en')">
                    </textarea>
                </base-input>
              </div>
            </div>
            <!--
             <div class="row">
              <div class="col-md-6">
                <base-input :label="$t('data_input.bemerkung_techniker')">
                    <textarea class="form-control" v-model="item.bemerkung_techniker" rows="3" :placeholder="$t('data_input.bemerkung_techniker_placeholder')"></textarea>
                  </base-input>
              </div>
              <div class="col-md-6">
                <base-input :label="$t('data_input.bemerkung_techniker_en')">
                    <textarea class="form-control" v-model="item.bemerkung_techniker_en" rows="3" :placeholder="$t('data_input.bemerkung_techniker_placeholder_en')"></textarea>
                  </base-input>
              </div>
             </div>
             -->
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import ImageUpload from '../ImageUpload'
import DocumentUpload from '@/components/DocumentUpload'
// import ImageEditor from '@/components/ImageAnnotator/ImageAnnotator'
import Overlay from '@/components/FullscreenOverlay'
import { mapGetters, mapActions } from 'vuex'
import { required } from "vuelidate/lib/validators";
import ImageEditor from "@/components/ImageEditor/ImageEditor";
import { dataURItoBlob, resizeImage } from '@/util/data'
import Material from './Material'
import FlatPicker from "vue-flatpickr-component";
import Vue from "vue"
import api from "@/util/api.js";

export default {
  name: 'DataInputForm',
  components: {
    RouteBreadCrumb,
    ImageUpload,
    DocumentUpload,
    ImageEditor,
    Overlay,
    Material,
    FlatPicker
  },
  computed: {
    ...mapGetters([
      'project',
      'position',
      'positions',
      'rides',
      'project_orders',
      'projectAddresses',
      'currentProjectAddress',
      'currentLocale',
      //'options',
      'globalOptions',
      'users'
    ]),
    annotations() {
      // We have to put the current signed URL into the annotation string

      if (this.item) {
        if (!this.item.bhb_annotation) return this.item.bhb_annotation
        const annotation = JSON.parse(this.item.bhb_annotation)
        if (!annotation) return annotation
        const image = this.item.documents
          ? this.item.documents.find(doc => doc.path === 'szene')
          : null
        annotation.backgroundImage.src = image ? image.url : null
        if (image) {
          const tempImage = new Image()
          tempImage.crossOrigin = "Anonymous"
          tempImage.src = image.url
        }

        return JSON.stringify(annotation)
      } else {
        return JSON.stringify({
          backgroundImage: {
            src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
          }})
        }
      },
      qcEmployee() {
        const internalUsers = this.users.filter(u => u.role.name !== 'Kunde');
        return internalUsers;
      },
      projectRides() {
        const p_address = this.projectAddresses.find(pa => pa.id==this.item.p_address_id)
        return p_address ? this.rides.filter(r => r.address_id === p_address.address_id) : this.rides
      },
      materials() {
        return this.item.materials ? this.item.materials.filter(mat => mat.type=='MAT') : []
      },
      constructions() {
        return this.item.materials ? this.item.materials.filter(mat => mat.type=='KON') : []
      },
      pt_numbers() {
        return this.project && this.project.pt_number ? this.project.pt_number.split(',') : []
      },
      pt_number_composed() {
        if (!this.item.pt_number && !this.project.pt_sync_enabled) {
          this.item.pt_number = this.project.pt_number + '-' + Number(this.item.key.replace('P', ''));
        }
        return this.item.pt_number;
      },
      isFormLoading () {
        return this.isAnnotationLoading
      },
      addressDeadlinePrint() {
        const p_address = this.projectAddresses.find(pa => pa.id==this.item.p_address_id)
        return p_address.deadline_print
      },
      customer_no() {
        const p_address = this.projectAddresses.find(pa => pa.id==this.item.p_address_id)
        if (!p_address) return this.project.customer_no
        return p_address.customer_no || this.project.customer_no
      },
      customer_orders() {
        let orders = this.project_orders.filter(o => o.order.Sell_to_Customer_No === this.customer_no)
        let additionalOrders = this.project_orders.filter(o => o.additional_customer_no != null);
        return [...orders, ...additionalOrders]
      },
      categories () {
        return this.globalOptions.filter(o => o.type=='ARTKAT')
      }
    },
    data() {
      return {
        constructionsVisible: true,
        isAnnotationLoading: false,
        showImageOverlay: false,
        showImageEditor: false,
        releasedCancel: false,
        purchaseField: false,
        item: {},
        areaqm: 0,
        szene: [],
        design: [],
        designs: [],
        price_1: 0,
        price_2: 0,
        deleted: [],
        price_total: 0,
        pt_number1: "",
        pt_number2: "",
        newAreaValue: '',
        newColorValue: '',
        newPositionValue: '',
        intern: '0',
        projectTools: {},
        options: [],
        optionsProjectId: '',
        dateTimePicker: {
          enableTime: true,
          time_24hr: true,
          dateFormat: "Y-m-d H:i:00",
          altInput: true,
          altFormat: "d.m.Y H:i"
        },
        hasMotherProject: false
      }
    },
    validations: {
      item: {
        key: {required},
        materials: {
          $each: {
            category_id: {required}
          }
        },
        ride_id: {required},
        order_no: {},
        p_address_id: {required},
        area_id: {required},
        bhb_position_id: {required}
      },
      constructions: {
        $each: {
          category_id: {
            required
          }
        }
      }
    },
    watch: {
      $route(val) {
         this.load();
      },
      item() {
        if (this.item.pt_number) {
          const pt = this.item.pt_number.split('-')
          this.pt_number1 = pt[0];
          this.pt_number2 = pt[1];
        }
        if(this.item.released) {
          this.alreadyReleased();
        }
        const p_address = this.projectAddresses.find(pa => pa.id == this.item.p_address_id)
        if (p_address) {
          let no_vehicle = this.rides.filter(r => r.address_id == p_address.address_id).map(r => r.vehicle_id).filter(v => v === null)
          if (no_vehicle.length) {
            this.missingVehicle()
          }
        }
      },
      pt_number1() {
        if (this.project.pt_sync_enabled) {
          this.pt_number1 = this.pt_numbers[0];
        } else {
          this.item.pt_number = this.pt_number1 + '-' + this.pt_number2;
        }
      },
      pt_number2() {
        if (this.project.pt_sync_enabled) {
          this.pt_number2 = Number(this.item.key.replace('P', ''));
        } else {
          if (this.pt_number2 === undefined) {
            this.pt_number2 = '';
          } else {
            this.item.pt_number = this.pt_number1 + '-' + this.pt_number2;
          }
        }
      },
      positions() {
        if (this.$route.params.posId==='add' && this.item.key==null) {
          this.item.key = this.nextPosNumber()
        }
      },
      'item.p_address_id' (val, old) {
        if (val !== old && old !== undefined) {
          const p_address = this.projectAddresses.find(pa => pa.id === val)
          const rides = p_address ? this.rides.filter(r => r.address_id === p_address.address_id) : this.rides
          this.item.ride_id = rides.length>0 ? rides[0].id : null
        }
        if (!this.customer_orders.find(o=>o.order_no==this.item.order_no)) {
          this.item.order_no = this.customer_orders.length>0 ? this.customer_orders[0].order_no : null
        }
      },
      '$store.state.positions.positions' (newValue, old) {
        // Bei Reload?
        //const existing = newValue[Number(this.item.key.replace('P', '')) - 1];
        //if (!existing) {
        if (old.length === 0) {
          this.resetItem()
        }
      }
      // 'item.materials': {
      //   handler () {
      //     const price = this.item.materials.reduce((sum, mat) => sum+parseFloat(mat.price_total), 0);
      //     this.$nextTick(() => {
      //       })
      //     this.item.price_total = this.round(price);
      //     console.log("TOTAL", this.item.price_total)
      //   },
      //   deep: true
      // }
    },
    methods: {
      ...mapActions([
        'getPosition',
        'loadPositionsForPorject',
        'setBhbState',
        'loadProjectOptions',
        'setBhbActionPL',
        'updatePositionForProject',
        'addPositionToProject'
      ]),
    addItem () {
    },
    copySelection() {
    },
    deleteSelection() {
    },
    async getOptionsByProjectId() {
      let projectMatches = await this.$store.dispatch('loadProjectMappingByChild', {
          type: 'project', 
          child_id: this.$route.params.id
        })

      if (projectMatches.length) {
        this.optionsProjectId = projectMatches[0].parent_id;
        this.hasMotherProject = true;
      } else {
        this.optionsProjectId = this.$route.params.id;
      }
      
      this.options = await this.loadProjectOptions(this.optionsProjectId)
    },
    purchaseChecked(val) {
      this.purchaseField = val;
    },
    async confirm({ callback, confirmMessage, successMessage }) {
      try {
        await this.$confirm(
          this.$t(confirmMessage),
          this.$t("message.warning"),
          {
            confirmButtonText: this.$t("message.ok"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning",
          }
        );
      } catch (error) {
        window.history.back();
        this.releasedCancel = true;
      }

      if (successMessage && !this.releasedCancel) {
        this.$message({ type: "success", message: successMessage });
      }
    },
    alreadyReleased() {
      this.confirm({
        confirmMessage: "message.already_released_warning",
        successMessage: this.$t("message.modify_released_confirmed"),
      });
    },
    missingVehicle() {
        this.$alert(
          this.$t('message.missingRideInfo'),
          this.$t("message.warning"),
          {
            confirmButtonText: this.$t("message.ok"),
            type: "warning",
            dangerouslyUseHTMLString: true,
            callback: () => {
              this.$router.push({ path: `/project/${this.project.id}/edit` })
            }
          }
        )
    },
    save(andNew, andNext) {
      const self = this

      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log('Validation failed', this.$v)
        return
      }

      if(this.pt_number2 === undefined || this.pt_number2 == '') {
        this.item.pt_number = null;
      }

      const isUpdate = !!this.item.id

      delete this.item.documents;
      delete this.item.released;

      const done = isUpdate
        ? this.$store.dispatch('updatePosition', { id: this.item.id, data: this.item })
        : this.$store.dispatch('createPosition', { data: this.item })

      done
        .then(res => this.item.id = parseInt(res.id))
        .then(() => {
          if (this.hasMotherProject) {
            if (isUpdate) {
              Vue.http.put('projectsync/position/' + this.item.id, {});
            } else {
              Vue.http.post('position/projectsync/' + this.item.id, {data: this.item, project_id: this.project.id});
            }
          }
        })
        .then(() => this.saveMaterials())
        .then(() => {
          //if (!this.hasMotherProject) {
            this.uploadImages()
          //}
        })
        .then(() => this.updatePositionForProject({ position: this.item, isUpdate }))

        .then(() => {
          if (this.project.pt_sync_enabled) {
            this.updatePT(this.item.id)
          }
        })

        .then(() => {
          this.$v.$reset();
          const checkFlag = () => {
            if (self.isAnnotationLoading == true) {
              window.setTimeout(checkFlag, 100); /* this checks the flag every 100 milliseconds*/
            } else {
              if (andNext) {
                var nextPosId = "";
                for (var i = 0; i < self.positions.length - 1; i++) {
                  if (self.positions[i].id == self.item.id) {
                    nextPosId = self.positions[i + 1].id;
                  }
                }
                this.$router.replace({ path: `/project/${this.$route.params.id}/data/${nextPosId}` });
              } else if (andNew) {
                this.$router.replace({ path: `/project/${this.$route.params.id}/data/add` });
                this.resetItem();
                this.$v.$reset();
              } else {
                window.history.back()
              }
            }
          }
          checkFlag()

        })
        .catch(error => {
          self.$notify({
            verticalAlign: "top",
            horizontalAlign: "right",
            message: error,
            type: "error"
          })
          // self.$notify.error({
          //   title: 'Error',
          //   message: error
          // })
        })
    },
    async saveMaterials() {
      const update = this.item.materials.filter(mat => mat.id);
      const create = this.item.materials.filter(mat => !mat.id && mat.mat_no != null);

      this.item.materials.forEach(m => {
        delete m.mat;
        delete m.ride;
        delete m.category
        delete m.article
        delete m.variant
      });

      create.forEach(m => m.position_id = this.item.id);

      if (update.length > 0) {
        this.$store.dispatch('updateMaterial', { data: _.keyBy(update, m => m.id) })
          .then(() => {
            if (this.hasMotherProject) {
              Vue.http.put('projectsync/material/' + update.map(m => m.id).join(','), {});
            }
          })
      }
      if (create.length > 0) {
        this.$store.dispatch('createMaterial', { data: create })
          .then(res => {
            if (this.hasMotherProject) {
              Vue.http.post('material/projectsync/' + res.ids.join(','), {data: create, project_id: this.project.id});
            }
          })
      }

      if (this.deleted.length > 0) {
        this.$store.dispatch('deleteMaterial', { id: this.deleted.map(m => m.id).join(',') })
          .then(() => {
            if (this.hasMotherProject) {
              Vue.http.delete('material/projectsync/' + this.deleted.map(m => m.id).join(','), {});
            }
          })
      }
      this.$v.$reset()
    },
    async updatePT(posID) {
      try {
        await Vue.http.get('pt/sync/' + posID, {});
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Daten wurden an PT übertragen.',
          type: 'success'
        })
      } catch (response) {
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Fehler bei Übertragung an PT: <br>' + response.body.message,
          type: 'error'
        });
      }
      this.isAnnotationLoading = false;
    },
    onSceneChanged(docs) {
      this.szene = docs;
    },
    annotationChanged(annotation) {
      // JVI: Wenn die Position noch nicht gespeichert wurde, schlägt das hier fehl...
      if (!this.item.id) return;

      const itemId = this.item.id
      const self = this;
      this.isAnnotationLoading = true;

      const annotationBlob = dataURItoBlob(this.$refs.imageEditor.getDataUrl())

      const image = new File([annotationBlob], 'annotation.png')

      // upload
      if (annotation.type === 'existing') {
        self.item.bhb_annotation = JSON.stringify(annotation.json);
        return self.$store.dispatch('updatePosition', { id: itemId, data: self.item })
          .then(() => resizeImage({ maxSize: 600, file: annotationBlob }))
          .then(imgBlob => {
            const image = new File([imgBlob], 'annotation.png')
            return this.$store.dispatch('uploadDocument', { type: 'position', id: itemId, files: { annotation: [image] }, api: true })
          })
          .then(stop)
      } else if (annotation.type === 'new') {
        return this.$store.dispatch('uploadDocument', {
          type: 'position', id: itemId, files: {
            szene: [annotation.image]
          }, api: true
        })
          .then(async () => {
                if (this.hasMotherProject) {
                  let posMap = await this.$store.dispatch('loadProjectMappingByChild', {
                                          type: 'position', 
                                          child_id: itemId
                                        });
                  
                  let parentPos = posMap[0].parent_id;
                  
                  this.$store.dispatch('uploadDocument', { type: 'position', id: parentPos, files: {szene: [annotation.image]}, api: true })
                  .then(() => {
                    Vue.http.post('document/projectsync/' + itemId, {
                                                                          project_id: this.project.id,
                                                                          data: {type: 'position',
                                                                                path: 'szene',
                                                                                parent_pos_id: parentPos}
                                                                          })
                  })
                }
          })
          .then(() => {
            return this.$store.dispatch("getDocs", {
              type: "position",
              id: itemId,
              api: true
            })
          })
          .then(items => {
            return items
              .filter(item => item.path === 'szene')
              .reverse()
          })
          .then(docs => {
            annotation.json.backgroundImage.src = docs[0].url;
            self.item.bhb_annotation = JSON.stringify(annotation.json);
            return this.$store.dispatch('updatePosition', { id: itemId, data: self.item })
          })
          .then(() => resizeImage({ maxSize: 600, file: annotationBlob }))
          .then(imgBlob => {
            const image = new File([imgBlob], 'annotation.png')
            return this.$store.dispatch('uploadDocument', { type: 'position', id: itemId, files: { annotation: [image] }, api: true })
              .then(async () => {
                    if (this.hasMotherProject) {
                      let posMap = await this.$store.dispatch('loadProjectMappingByChild', {
                                              type: 'position', 
                                              child_id: itemId
                                            });
                      
                      let parentPos = posMap[0].parent_id;
                      
                      this.$store.dispatch('uploadDocument', { type: 'position', id: parentPos, files: {annotation: [image]}, api: true })
                      .then(() => {
                        Vue.http.post('document/projectsync/' + itemId, {
                                                                              project_id: this.project.id,
                                                                              data: {type: 'position',
                                                                                    path: 'annotation',
                                                                                    parent_pos_id: parentPos}
                                                                              })
                      })
                    }
              })
          })
          .then(stop)
      } else if (annotation.type === 'data') {
        return Promise.resolve()
          .then(() => resizeImage({ maxSize: 600, file: annotationBlob }))
          .then(imgBlob => {
            const image = new File([imgBlob], 'annotation.png')
            return this.$store.dispatch('uploadDocument', { type: 'position', id: itemId, files: { annotation: [image] }, api: true })
          })
          .then(stop())
      }



      function stop() {
        self.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: self.$t('notifications.image.saved'),
          type: "success"
        })
        setTimeout(() => self.isAnnotationLoading = false, 500)

      }
    },
    async annotationDeleted() {
      try {
        await this.$confirm(
          `Wollen Sie dieses Szene wirklich löschen?`,
          `Szene löschen`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        )
        const docs = this.item.documents.filter(doc => doc.path === 'szene' || doc.path === 'annotation')
        await this.$store.dispatch('deleteDocApi', { docs: docs })
      } catch (error) {
        return;
      }
    },
    uploadImages() {
      const self = this
      this.$refs.imageEditor.save();

      const files = {
        'designs[]': this.designs || [],
      }

      if (this.designs.length > 0) {
        const bhbState = this.designs.length > 0 || this.item.state_bhb === "KORREKTUR" ?
          (this.item.state_bhb === 'LEER' ? 'LEER' : this.item.state_bhb)
          : 'LEER'

        this.item.state_bhb = bhbState

        const actionPL = bhbState === 'LEER' ? true : false
        return this.$store.dispatch('uploadDocument', { type: 'position', id: this.item.id, files: files, api: true })
          .then(() => this.setBhbState({ posId: this.item.id, bhbState }))
          .then(() => this.setBhbActionPL({ posId: this.item.id, actionPL }))
          .then(async () => {
                if (this.hasMotherProject) {
                  let posMap = await this.$store.dispatch('loadProjectMappingByChild', {
                                          type: 'position', 
                                          child_id: this.item.id
                                        });
                  
                  let parentPos = posMap[0].parent_id;
                  
                  this.$store.dispatch('uploadDocument', { type: 'position', id: parentPos, files: files, api: true })
                  .then(() => {
                    Vue.http.post('document/projectsync/' + this.item.id, {
                                                                          project_id: this.project.id,
                                                                          data: {type: 'position',
                                                                                path: 'designs',
                                                                                parent_pos_id: parentPos}
                                                                          })
                  })
                }
          });
      }

    },
    onDesignRemoved(docs) {
      const bhbState = docs.length > 0 || this.item.state_bhb !== 'LEER' ?
        (this.item.state_bhb === 'LEER' ? 'LEER' : this.item.state_bhb)
        : 'LEER'
      this.setBhbState({ posId: this.item.id, bhbState })
    },
    // https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
    round(value) {
      return Math.round(value * 100 + 0.00001) / 100;
    },
    addMaterial(type) {
      if (!this.item.materials) {
        this.item.materials = []
      }
      if (type === 'KON') {
        this.constructionsVisible = true;
      }

      const matCats = this.categories.filter((c) => this.setCategoryOptions(type).includes(c.name));
      const last = this.item.materials.length > 0 ? this.item.materials[this.item.materials.length - 1] : null;
      const first = this.item.materials.find(mat => mat.type == type);
      matCats.unshift({id:null, name:'Auswählen', name_en:'Select'});
      this.item.materials.push({
        mat: first ? first.mat : null,
        category_id: this.categories.length > 0 ? matCats[0].id : null,
        mat_no: first ? first.mat_no : null,
        article: first ? first.article : null,
        VariantCode: first ? first.VariantCode : null,
        variant: first ? first.variant : null,
        position_id: this.item.id,
        sort: this.item.materials.length,
        quantity: 1,
        motiv: '',
        price: first ? first.price : 0,
        width: first ? first.width : null,
        height: first ? first.height : null,
        ride_id: last ? last.ride_id : null,
        state_komm: 'OFFEN',
        state_lade: 'OFFEN',
        state_rueck: 'OFFEN',
        type: type
      })
    },
    deleteMaterial(mat) {
      if (mat.id) {
        this.deleted.push(mat)
      }
      const index = this.item.materials.indexOf(mat);
      this.item.materials.splice(index, 1);
      this.materialsChanged()
    },
    corVariantChanged() {
      const constructions = this.item.materials.filter(mat => mat.type == 'KON')
      const materials = this.item.materials.filter(mat => mat.type == 'MAT')

      if (constructions.length == 0 || materials.length == 0) return;
      const master = constructions[0];
      //WARNING! MAGIC STRING
      if (master.VariantCode === "UMLAUF005") {
        this.item.materials.pop();
        for (let index = 0; index < materials.length; index++) {
          const element = materials[index];
          
          this.item.materials.push({ ...master, width:element.width, height:element.height,quantity:element.quantity,price_unit: element.price_unit, price_total: element.price_total, })
        }
      }
    },
    materialsChanged() {
      this.syncArticles('MAT');
      this.syncArticles('KON');

      const price = this.item.materials.reduce((sum, mat) => sum + parseFloat(mat.price_total || 0), 0);
      const price_percent = this.item.materials.reduce((sum, mat) => sum + parseFloat(mat.price_percent || 0), 0);
      // this.price_total = this.round(price);
      this.$set(this.item, 'price_total', this.round(price))
      this.$set(this.item, 'price_percent', this.round(price_percent))
    },
    syncArticles(type) {
      const materials = this.item.materials.filter(mat => mat.type == type)
      if (materials.length == 0) return;
      const master = materials[0];

      // if (type === 'KON' && master.VariantCode === "UMLAUF005") {
      //   this.item.materials.filter(mat => mat.type === 'MAT').forEach((m) => {
      //     this.item.materials.push({...m, type: 'KON'})
      //   })
      // }

      materials.forEach(mat => {
        mat.mat_no = master.mat_no
        mat.VariantCode = master.VariantCode
        mat.article = master.article
        mat.variant = master.variant
      })
    },
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    nextPosNumber() {
      let max
      if (this.positions.length == 0) {
        max = 1
      }
      const nums = this.positions.map(pos => {
        const matches = pos.key.match(/\d+$/);
        if (matches) {
          return parseInt(matches[0])
        }
        return 0
      })

      if (nums.length > 0) {
        max = Math.max(...nums) + 1
      }
      return 'P' + this.pad(max, 4)
    },
    load() {
      this.$store.dispatch('findPositions', {
        data: {
          and: { id: this.$route.params.posId },
          with: {
            'materials': {
              'many': 'material',
              'that': 'position_id',
              'query': {
                'with': {
                  'article': {
                    'one': 'article',
                    'this': 'mat_no',
                    'that': 'No'
                  },
                  'variant': {
                    'one': 'variant',
                    'this': 'VariantCode',
                    'that': 'Code'
                  },
                  'ride': {
                    'one': 'ride',
                    'this': 'ride_id',
                    'query': {
                      'with': {
                        'vehicle': { 'one': 'vehicle', 'this': 'vehicle_id' }
                      }
                    }
                  },
                  'category': {
                    'one': 'option',
                    'this': 'category_id',
                  }
                },
              }
            },
            documents: {
              'many': 'document',
              'this': 'id',
              'that': 'item_id',
              'query': {
                'and': {
                  'type': 'position'
                }
              }
            },
          }
        },
        options: { commit: false }
      }
      )
        .then(items => {
          this.item = items[0];
          this.item.priority = this.item.priority == 1;
          this.item.quality_control = this.item.quality_control == 1;
          this.item.divider_sheet = this.item.divider_sheet == 1;
          this.item.released = this.item.rel_business == 1 && this.item.state_bhb == 'FREIGEGEBEN';
        })
    },
    resetItem() {
      // JVI: Die Berechnung der PT-Nummer unten schlägt fehl, wenn an dieser Stelle das Projekt
      // noch nicht geladen ist, also nach aktualisieren. Sollte aber ja hier nciht passieren...

      const highestSort = Math.max(...this.positions.map(p => p.sort));
      const ptNumberBySync = this.project.pt_sync_enabled ? '' : this.pt_numbers[0];
      const matCats = this.categories.filter((c) => this.setCategoryOptions('MAT').includes(c.name));
      //const konCats = this.categories.filter((c) => this.setCategoryOptions('KON').includes(c.name));
      matCats.unshift({id:null, name:'Auswählen', name_en:'Select'});
      this.item = {
        project_id: this.$route.params.id,
        key: this.nextPosNumber(),
        pt_number: ptNumberBySync,
        price_total: 0,
        sort: highestSort + 1,
        p_address_id: this.item.p_address_id,
        order_no: this.item.order_no,
        ride_id: this.item.ride_id,
        documents: [],
        priority: false,
        priority_internal: true,
        priority_date: new Date(),
        materials: [
          {
            // Achtung! Keine position_id
            mat_no: null,
            category_id: matCats.length > 0 ? matCats[0].id : null,
            article: null,
            VariantCode: null,
            variant: null,
            sort: 0,
            quantity: 1,
            price_unit: 0,
            price_total: 0,
            price: 0,
            purchase: 0,
            state_komm: 'OFFEN',
            state_lade: 'OFFEN',
            state_rueck: 'OFFEN',
            type: 'MAT'
          },
          /*
          {
            // Achtung! Keine position_id
            mat_no: null,
            category_id: konCats.length > 0 ? konCats[0].id : null,
            article: null,
            VariantCode: null,
            variant: null,
            sort: 1,
            quantity: 1,
            price_unit: 0,
            price_total: 0,
            price: 0,
            state_komm: 'OFFEN',
            state_lade: 'OFFEN',
            state_rueck: 'OFFEN',
            type: 'KON'
          },
          */
        ],
        state_bhb: 'LEER',
        bhb_annotation: ''
      }
      this.$refs.imageEditor.clear();

      this.designs = []
      this.$refs.docUpload.reset()
      this.constructionsVisible = false;

      if (!this.item.p_address_id && this.projectAddresses.length == 1) {
        this.item.p_address_id = this.projectAddresses[0].id;
      }
      if (this.rides.length == 1) this.item.ride_id = this.rides[0].id;
      if (this.project_orders.length == 1) this.item.order_no = this.project_orders[0].order_no
    },
    async saveNewOption(type) {
      let value
      switch (type) {
        case 'AREA':
          value = this.newAreaValue
          break
        case 'COLOR':
          value = this.newColorValue.toUpperCase()
          break
        case 'BHBPOS':
          value = this.newPositionValue
          break
      }

      const data = {
        project_id: this.optionsProjectId,
        type,
        name: value
      }

      await this.$store.dispatch("createOption", { data })
      this.options = await this.loadProjectOptions(this.optionsProjectId)
      this.newAreaValue = ''
      this.newColorValue = ''
      this.newPositionValue = ''

    },
    setCategoryOptions($type) {
      if ($type === "MAT") {
        return ["Produktionsware", "Kundenware", "Einkaufsware", "Externes Lager", "Infoartikel"];
      } else {
        return ["APA Lagerware", "Verbrauchsmaterial", "Angebotsartikel", "Inhousearbeiten"];
      }
    }
  },
  mounted() {
    if (this.$route.params.posId === 'add') {
      this.resetItem();
      if (this.projectAddresses.length == 1) this.item.p_address_id = this.projectAddresses[0].id;
      if (this.rides.length == 1) this.item.ride_id = this.rides[0].id;
      if (this.project_orders.length == 1) this.item.order_no = this.project_orders[0].order_no
    } else {
      this.load();
      //
      //
      // const position = this.positions.find(p => p.id == this.$route.params.posId);
      // this.item = Object.assign({}, position);
      // this.item.materials = this.item.materials.map(m => Object.assign({}, m));
      // this.item.priority = this.item.priority==1;
      // this.item.purchase = this.item.purchase==1;
      // this.item.purchase2 = this.item.purchase2==1;
      // this.materialsChanged()
    }

    this.getOptionsByProjectId();

    if (this.project.id) {
      (async () => {
        this.projectTools = await api("findProject_tools")
          .and("project_id", this.project.id)
          .run();
      })();
    }
  }
};
</script>
<style>
input[type=number] {
  text-align: right;
}
</style>
